import { Action } from 'redux'
import { StepPath } from '~src/types/wizard'

export enum WizardActionName {
  TOGGLE_WIZARD_STEP = '[Wizard] TOGGLE_WIZARD_STEP',
  SET_COMPLETED_STEPS = '[Wizard] SET_COMPLETED_STEPS',
}

export interface ToggleWizardStep extends Action {
  type: typeof WizardActionName.TOGGLE_WIZARD_STEP
  newStepPath: StepPath
}

export interface CompletedStepsActionPayload {
  currentStepPath: StepPath
  isCurrentStepValid: boolean
}

export interface SetCompletedStepsAction extends Action {
  type: typeof WizardActionName.SET_COMPLETED_STEPS
  payload: CompletedStepsActionPayload
}

export type WizardActionTypes = ToggleWizardStep | SetCompletedStepsAction
