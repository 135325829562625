import {
  KnifeActionTypes,
  KnifeActionName,
  SetMaterialActionPayload,
} from '~src/types/actions/knife-actions'
import { PickedKnives } from '~src/types/knife'

export const setPickedKnivesAC = (
  pickedKnives: PickedKnives
): KnifeActionTypes => ({
  type: KnifeActionName.SET_KNIVES,
  pickedKnives,
})

export const setMaterialTypeAC = (
  payload: SetMaterialActionPayload
): KnifeActionTypes => ({
  type: KnifeActionName.SET_MATERIAL,
  payload,
})
