import {
  SteelType,
  FerrulesType,
  HandleType,
  PickedKnives,
} from '~src/types/knife'

export enum StepPath {
  PickKnives = 'pick-knives',
  ChooseSteelType = 'choose-steel-type',
  PickHandleType = 'pick-handle-type',
  ChooseFerrules = 'choose-ferrules',
  Summary = 'summary',
}

export type WizardStepData =
  | PickedKnives
  | SteelType
  | HandleType
  | FerrulesType
