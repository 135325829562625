import { Action } from 'redux'

import { PickedKnives, Material, KnifePart } from '~src/types/knife'

export enum KnifeActionName {
  SET_KNIVES = '[Knife] SET_KNIVES',
  SET_MATERIAL = '[Knife] SET_MATERIAL',
  SET_MATERIAL_CHEAP_SET = '[Knife] SET_MATERIAL_CHEAP_SET',
}

export interface SetPickedKnivesAction extends Action {
  type: typeof KnifeActionName.SET_KNIVES
  pickedKnives: PickedKnives
}

export interface SetMaterialActionPayload {
  knifePart: KnifePart
  material: Material
}

export interface SetMaterialAction extends Action {
  type: typeof KnifeActionName.SET_MATERIAL
  payload: SetMaterialActionPayload
}

export type KnifeActionTypes = SetPickedKnivesAction | SetMaterialAction
