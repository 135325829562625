import {
  FerrulesComponent,
  MaterialEndurance,
  MaterialWeight,
  Metal,
} from '~src/types/knife'

export const ferruleMaterials: FerrulesComponent[] = [
  {
    type: Metal.Damasteel,
    name: { PL: 'Damask', EN: '' },
    description: { PL: 'Damask', EN: '' },
    weight: MaterialWeight.Heavy,
    endurance: MaterialEndurance.three,
  },
]
