import { PickedKnives, SteelType } from '~src/types/knife'
import { Validators } from '~src/types/validators'

const isArrayEmpty: Validators = (array): boolean => !array.length
const isValueSet: Validators = (value): boolean => !!value

const pickedKnivesValidator: Validators = (data: PickedKnives): boolean =>
  !isArrayEmpty(data)

const materialTypeValidator: Validators = (data: SteelType): boolean =>
  isValueSet(data)

export { pickedKnivesValidator, materialTypeValidator }
