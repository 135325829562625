import { ActionCreator } from 'redux'
import {
  CompletedStepsActionPayload,
  SetCompletedStepsAction,
  ToggleWizardStep,
  WizardActionName,
} from '~src/types/actions/wizard-actions'
import { StepPath } from '~src/types/wizard'
import {
  SetLanguageAction,
  SettingsActionName,
} from '~src/types/actions/settings-actions'

export const toggleWizardStepAC: ActionCreator<ToggleWizardStep> = (
  newStepPath: StepPath
): ToggleWizardStep => ({
  type: WizardActionName.TOGGLE_WIZARD_STEP,
  newStepPath,
})

export const setCompletedStepsAC: ActionCreator<SetCompletedStepsAction> = (
  payload: CompletedStepsActionPayload
): SetCompletedStepsAction => ({
  type: WizardActionName.SET_COMPLETED_STEPS,
  payload,
})

export const toggleLanguageAC: ActionCreator<SetLanguageAction> = (
  lang
): SetLanguageAction => ({
  type: SettingsActionName.SET_LANGUAGE,
  lang,
})
