import {
  HandleComponent,
  MaterialEndurance,
  MaterialWeight,
  Wood,
} from '~src/types/knife'

export const handleMaterials: HandleComponent[] = [
  {
    type: Wood.Ebony,
    name: { PL: 'Heban', EN: 'Ebony' },
    description: {
      PL: [
        'Heban to tworzywo rzeźbiarzy.  Od wieków kojarzony jest ze sztuką, jednak jako materiał bardzo dobrze nadaje się również do zastosowań w rzemiośle i lutnictwie. Do naszych noży używamy odmiany afrykańskiego hebanu pochodzącego z Mozambiku, o charakterystycznym rysunku jaśniejszych słojów. Obróbka hebanu wymaga doświadczenia i bardzo ostrych narzędzi. Jest to bardzo twarde, gęste drewno.  Heban charakteryzuje się też bardzo wysoką odpornością na działanie wody.',
        'Pielęgnacja  |  Heban jest naturalnie oleistym gatunkiem o zwartej strukturze. Niemniej warto co pewien czas przetrzeć rękojeści olejem ( np. tungowym, lnianym ) dla wydobycia głębszych kontrastów i dodatkowej ochrony przed wodą.',
      ],
      EN: [],
    },
    weight: MaterialWeight.Medium,
    endurance: MaterialEndurance.four,
  },
  {
    type: Wood.Ironwood,
    name: { PL: 'Desert Ironwood (Olneya tesota)', EN: '' },
    description: {
      PL: [
        'Pustynne Drzewo Żelazne rośnie tylko w jednym miejscu na Ziemi - na pustyni Sonora (część Arizony i Kalifornii oraz część Meksyku). Jest ono prawnie chronione, jednak dozwolone jest zbieranie obumarłych gałęzi i pni, które są źródłem jednego z najcenniejszych materiałów wykorzystywanych na  oprawy wysokiej jakości narzędzi i do budowy instrumentów muzycznych.',
        'Ironwood to jedno z najpiękniejszych, najcięższych (tonie w wodzie!), najtwardszych i najbardziej odpornych na działanie wody drewien, jakie stworzyła natura. Ze względu na dużą gęstość, ironwood nie jest łatwe w obróbce, ale czas poświęcony na jego wykończenie rekompensowany jest pięknym rysunkiem słojów i niespotykaną trwałością.',
        'Pielęgnacja  |  Ironwood jest naturalnie oleistym gatunkiem o zwartej strukturze, dzięki temu nie trzeba go dodatkowo konserwować. Niemniej warto co pewien czas przetrzeć rękojeści olejem ( np. tungowym, lnianym ) dla wydobycia głębszych kontrastów i dodatkowej ochrony przed wodą.',
      ],
      EN: [],
    },
    weight: MaterialWeight.Heavy,
    endurance: MaterialEndurance.five,
  },
  {
    type: Wood.Olivewood,
    name: { PL: 'Oliwka (Olea)', EN: '' },
    description: {
      PL: [
        'Drzewo oliwne uprawiane jest od 4 tysięcy lat. W starożytności, zwłaszcza w Azji Mniejszej , drzewa i gaje oliwne były otoczone szczególną czcią, jednak już wtedy, ze względu na jej właściwości, oliwkę wykorzystywano również jako materiał konstrukcyjny.',
        'Współcześnie drewno oliwne jest szeroko stosowane przez artystów i rzemieślników. Jego niepowtarzalne kontrastowe usłojenie i ciepły, miodowy odcień sprawia, że przedmioty wykonane z oliwki cieszą się dużą popularnością.',
        'Drewno oliwne ma wysoką gęstość, ale jest bardzo niejednorodne. Nieregularne słoje często prowadzą do powstawania pęknięć, co utrudnia pracę z tym gatunkiem. Niemniej piękne usłojenie i przyjemny zapach tego drewna rekompensują dodatkowe trudności.',
        'Pielęgnacja  | Odpowiednio zakonserwowana oliwka nadaje się na oprawy narzędzi kuchennych. Ale żeby zachować ją w dobrej kondycji i wyglądzie, trzeba poświęcić jej odrobinę uwagi. W procesie produkcyjnym oliwkę poddajemy impregnacji ciśnieniowej, co zwiększa jej odporność na działanie wody, należy jednak unikać niepotrzebnego kontaktu z wilgocią. Rękojeści z drewna oliwnego dobrze jest regularnie nacierać olejem dla wzmocnienia warstwy chroniącej drewno.',
      ],
      EN: [],
    },
    weight: MaterialWeight.Light,
    endurance: MaterialEndurance.three,
  },
  {
    type: Wood.Walnut,
    name: { PL: 'Orzech włoski (Juglans regia L.)', EN: '' },
    description: {
      PL: [
        'Drewno orzecha włoskiego od dawna było cenione jako materiał stolarski. Do dziś używa się go w rzemiośle np. na oprawy strzelb i pistoletów. Do naszych noży stosujemy wyłącznie orzech w wersji stabilizowanej ciśnieniowo (czyli przesyconej żywicami). Wynika to z jego niewystarczającej naturalnej odporności na wilgoć, która w środowisku kuchennym jest kluczowa. Po stabilizacji  drewno jest znacznie odporniejsze na działanie wody.  Okładziny rękojeści przygotowujemy z orzecha ciętego w poprzek włókien, co daje znacznie głębszy - brązowo czekoladowy  odcień, z charakterystycznymi ciemniejszymi odbarwieniami i jaśniejszymi przebłyskami sęków.',
        'Pielęgnacja  |  Drewna stabilizowane wykazują dobrą odporność na wilgoć, jednak żadna impregnacja nie chroni drewna całkowicie. Warto co pewien czas przetrzeć rękojeści olejem ( np. tungowym, lnianym ) dla wydobycia głębszych kontrastów i dodatkowej ochrony przed wodą.',
      ],
      EN: [],
    },
    weight: MaterialWeight.Medium,
    endurance: MaterialEndurance.four,
  },
  {
    type: Wood.PaintedWood,
    name: { PL: 'Drewno barwione', EN: '' },
    description: {
      PL: [
        'Drewno barwione powstaje w procesie polegającym na przesyceniu porowatej struktury drewna żywicami o małej lepkości. Do żywic dodajemy specjalne barwniki. Proces przesycania można podzielić na etapy i w bloczkach łączyć kilka kolorów żywic. Sposób , w jaki barwniki rozejdą się w drewnie jest uzależniony od ułożenia sęków, różnic w gęstości i usłojenia. Efekt końcowy jest zawsze niespodzianką.',
        'Do barwienia używamy głównie klonu, brzozy i wierzby. Najczęściej na rękojeści wykorzystuje się tzw. czeczoty , czyli części pni o najciekawszym rysunku - pełne sęków, o szczególnie poskręcanych słojach.',
        'Pielęgnacja  |  Drewna stabilizowane wykazują dobrą odporność na wilgoć, jednak żadna impregnacja nie chroni drewna całkowicie. Warto co pewien czas przetrzeć rękojeści olejem ( np. tungowym, lnianym ) dla wydobycia głębszych kontrastów i dodatkowej ochrony przed wodą.',
      ],
      EN: [],
    },
    weight: MaterialWeight.Medium,
    endurance: MaterialEndurance.four,
  },
]
