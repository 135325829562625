import React, { useState } from 'react'

import _ from 'lodash'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button } from 'antd'
import { AppState } from '~src/store/create-store'
import { KnivesSet } from '~src/types/types-store'

import sendOrder, { OrderEmailData } from '~src/api/email-api'

const { TextArea } = Input

type Props = KnivesSet
const StepSummary = ({
  pickedKnives,
  configuration,
}: Props): React.ReactElement<Props> => {
  const { ferrules, handle, steel } = configuration

  const [customerEmail, setCustomerEmail] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [customerPhone, setCustomerPhone] = useState('')
  const [customerDescription, setCustomerDescription] = useState('')

  const renderPricingTable = pickedKnives.map(knife => (
    <dl
      key={`summary-${knife}`}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div> {knife} </div>
      <div>
        {' '}
        <dd>{steel}</dd>{' '}
      </div>
      <div>
        {' '}
        <dd>{handle}</dd>{' '}
      </div>
      <div>
        {' '}
        <dd>{ferrules}</dd>{' '}
      </div>
    </dl>
  ))

  const emailData: OrderEmailData = {
    customerEmail,
    customerDescription,
    customerName,
    customerPhone,
    knives: `<b> ${_.join(pickedKnives, ', ')} </b>`,
    materials: `Steel: ${steel} | Handle: ${handle} | Ferrules: ${ferrules}`,
  }

  const contactForm = (
    <Form className="login-form">
      <Form.Item>
        <Input
          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
          value={customerEmail}
          onChange={e => setCustomerEmail(e.target.value)}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item>
        <Input
          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
          value={customerName}
          onChange={e => setCustomerName(e.target.value)}
          placeholder="Your name"
        />
      </Form.Item>
      <Form.Item>
        <Input
          prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
          value={customerPhone}
          onChange={e => setCustomerPhone(e.target.value)}
          placeholder="Phone number"
        />
      </Form.Item>
      <Form.Item>
        <TextArea
          value={customerDescription}
          onChange={e => setCustomerDescription(e.target.value)}
          placeholder="Description"
        />
      </Form.Item>
      <Form.Item>
        <Button
          onClick={() => sendOrder(emailData)}
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          Make order
        </Button>
      </Form.Item>
    </Form>
  )

  return (
    <div style={{ maxWidth: '500px', margin: '5em auto' }}>
      <h2>Your order:</h2>
      <dl style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {' '}
          <dt>Picked knives:</dt>{' '}
        </div>
        <div>
          {' '}
          <dt>Steel type:</dt>{' '}
        </div>
        <div>
          {' '}
          <dt>Handle type:</dt>{' '}
        </div>
        <div>
          {' '}
          <dt>Ferrules:</dt>{' '}
        </div>
      </dl>
      {renderPricingTable}
      {contactForm}
    </div>
  )
}

const mapStateToProps = ({ knivesSet }: AppState): KnivesSet => knivesSet

export default connect(mapStateToProps)(StepSummary)
