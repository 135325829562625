import React from 'react'
import {
  Col,
  Descriptions,
  Rate,
  Divider,
  Drawer,
  Layout,
  Row,
  Card,
  Icon,
  notification,
} from 'antd'

import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import sharedKnivesModel from '~src/models/knives/Shared.knives'

import { AppState } from '~src/store/create-store'
import { setPickedKnivesAC } from '~src/actions/knife-actions'
import { KnifeConfig, KnifeName } from '~src/types/knife'
import { setCompletedStepsAC } from '~src/actions/wizard-actions'
import { pickedKnivesValidator } from '~src/validators'
import {
  CombinedStepPickKnivesProps,
  StepPickKnivesLinkDispatchProps,
  StepPickKnivesLinkStateProps,
} from '~components/wizard-steps/types'

import translate from '~src/dicts/en-pl-dict'

const { useState } = React
const { Meta } = Card

const StepPickKnives = ({
  setPickedKnives,
  pickedKnives,
  setCompletedSteps,
  stepPath,
  lang,
}: CombinedStepPickKnivesProps): React.ReactElement<
  CombinedStepPickKnivesProps
> => {
  const [filteredOptions, setFilteredOptions] = useState(sharedKnivesModel)
  const [drawerIsFolded, foldDrawer] = useState(false)
  const [descriptionKnife, setKnifeDescription] = useState({})

  const handleSelectChange = (knife: string): void => {
    const mergedKnives = [...pickedKnives, knife]
    setPickedKnives(mergedKnives)

    const isCurrentStepValid = pickedKnivesValidator(mergedKnives)

    const updatedKnives = sharedKnivesModel.filter(
      (sharedKnife): boolean => !mergedKnives.includes(sharedKnife.name)
    )

    setFilteredOptions(updatedKnives)
    setCompletedSteps({
      isCurrentStepValid,
      currentStepPath: stepPath,
    })
  }

  const handleRemoveKnife = (knife: strung): void => {
    const updatedKnives = pickedKnives.filter((k): boolean => k !== knife)

    setPickedKnives(updatedKnives)

    const updatedFilteredKnives = sharedKnivesModel.filter(
      (sharedKnife): boolean => !updatedKnives.includes(sharedKnife.name)
    )

    setFilteredOptions(updatedFilteredKnives)
  }

  const addKnifeNotify = (type, knifeTitle) => {
    notification[type]({
      message: `${translate('Dodałeś do zamówienia', lang)} ${knifeTitle}`,
    })
  }

  return (
    <Layout style={{ background: 'transparent' }}>
      <Row gutter={16}>
        <Divider orientation="left">{translate('Wybrane nóże', lang)}</Divider>
        {pickedKnives.map(
          (selectedKnife: KnifeName): React.ReactElement => {
            const knife = sharedKnivesModel.find(
              (modelKnife: KnifeConfig): boolean =>
                selectedKnife === modelKnife.name
            )
            return (
              <Col span={8}>
                <Card
                  style={{
                    width: 300,
                    margin: '10px auto 20px auto',
                    borderColor: 'green',
                  }}
                  hoverable
                  cover={
                    <img
                      style={{ margin: '25px auto', width: '80%' }}
                      alt="example"
                      src={knife.image}
                    />
                  }
                  actions={[
                    <Icon
                      type="minus-circle"
                      onClick={() => handleRemoveKnife(knife.name)}
                    />,
                    <Icon
                      type="info-circle"
                      onClick={() => {
                        setKnifeDescription(knife)
                        foldDrawer(true)
                      }}
                    />,
                  ]}
                >
                  <Meta
                    title={knife.title[lang]}
                    description="This is the description"
                  />
                </Card>
              </Col>
            )
          }
        )}
      </Row>
      <Row gutter={16}>
        <Divider orientation="left">{translate('Nóże', lang)}</Divider>
        {filteredOptions.map(
          (knife): React.ReactElement => (
            <Col span={8}>
              <Card
                hoverable
                style={{ width: 300, margin: '10px auto 20px auto' }}
                cover={
                  <img
                    style={{ margin: '25px auto', width: '80%' }}
                    alt="example"
                    src={knife.image}
                  />
                }
                actions={[
                  <Icon
                    type="plus-circle"
                    onClick={() => {
                      addKnifeNotify('success', knife.title[lang])
                      handleSelectChange(knife.name)
                    }}
                  />,
                  <Icon
                    type="info-circle"
                    onClick={() => {
                      setKnifeDescription(knife)
                      foldDrawer(true)
                    }}
                  />,
                ]}
              >
                <Meta
                  title={knife.title[lang]}
                  description="This is the description"
                />
              </Card>
            </Col>
          )
        )}
      </Row>
      <Drawer
        height="auto"
        visible={drawerIsFolded}
        title={drawerIsFolded ? descriptionKnife.title[lang] : 'EX'}
        placement="bottom"
        closable={false}
        onClose={() => foldDrawer(false)}
        keyboard
      >
        <Row>
          <Col span={6}>
            <img alt="knife-a" src={descriptionKnife.image} />
          </Col>
          <Col span={18}>
            <p>
              {descriptionKnife.description
                ? descriptionKnife.description[lang]
                : 'EX'}
            </p>
            <p>
              <Descriptions
                title={translate('Informacja', lang)}
                bordered
                layout="vertical"
                column={5}
              >
                <Descriptions.Item label={translate('Długość KT (mm)', lang)}>
                  {' '}
                  {descriptionKnife.length}{' '}
                </Descriptions.Item>
                <Descriptions.Item
                  label={translate('Szerokość brody (mm)', lang)}
                >
                  {descriptionKnife.height}
                </Descriptions.Item>
                <Descriptions.Item label={translate('Waga (g)', lang)}>
                  {descriptionKnife.weight}
                </Descriptions.Item>
                <Descriptions.Item label={translate('Profil szlifu', lang)}>
                  {' '}
                  {descriptionKnife.grindingProfile}{' '}
                </Descriptions.Item>
                <Descriptions.Item label={translate('Uniwersalność', lang)}>
                  <Rate disabled defaultValue={descriptionKnife.versatility} />
                </Descriptions.Item>
              </Descriptions>
            </p>
          </Col>
        </Row>
      </Drawer>
    </Layout>
  )
}

const mapStateToProps = ({
  knivesSet,
  app,
}: AppState): StepPickKnivesLinkStateProps => ({
  pickedKnives: knivesSet.pickedKnives,
  lang: app.language,
})

const mapDispatchToProps = (
  dispatch: Dispatch
): StepPickKnivesLinkDispatchProps => ({
  setPickedKnives: bindActionCreators(setPickedKnivesAC, dispatch),
  setCompletedSteps: bindActionCreators(setCompletedStepsAC, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepPickKnives)
