const EN_PL_DICT = {
  'Następny krok': 'Next step',
  Wstecz: 'Back',
  'Wybrane nóże': 'Picked knives',
  Nóże: 'Knives',
  'Nazwa noża': 'Knife name',
  Stal: 'Steel',
  Oprawa: 'Handle',
  Rękojeść: 'Handle',
  Okucia: 'Ferrule',
  Liczba: 'Quantity',
  Cena: 'Price',
  Suma: 'Summary',
  Podsumowanie: 'Summary',
  zł: 'pln',
  'Dodałeś do zamówienia': 'Added to order',
  'Dodaj nóż do zamówienia': 'Choose a knives to your set',
  Informacja: 'Information',
  'Długość KT (mm)': 'Length (mm)',
  'Szerokość brody (mm)': 'Height (mm)',
  'Waga (g)': 'Weight (g)',
  'Profil szlifu': 'Grinding profile',
  Uniwersalność: 'Versatility',
}

/**
 * Translator to english for common words/phrases.
 *
 * @param  phrase  Phrase who translate to english
 * @param  lang    Language code. If equal "PL", just return a "phrase" param.
 */
const translate = (phrase: string, lang: string) => {
  const translatedPhrase = EN_PL_DICT[phrase]

  if (typeof translatedPhrase === 'undefined') {
    throw new Error(`KLOSY ERROR: No translate for "${phrase}" phrase`)
  }

  if (lang === 'PL') return phrase
  return translatedPhrase
}

export default translate
