import React, { FC, ReactElement } from 'react'
// @ts-ignore
import _ from 'lodash'
import { Table } from 'antd'
import { ColumnProps } from 'antd/es/table'

import NumberFormat from 'react-number-format'
import {
  KnifeConfig,
  KnifeName,
  KnifePart,
  PartType,
  Pricing,
} from '~src/types/knife'

import sharedKnivesModel from '~src/models/knives/Shared.knives'

import { CalculatorTableRow } from './types'
import { KnivesSet, App } from '~src/types/types-store'

import { CURRENCY } from '~src/constants/constants'
import translate from '~src/dicts/en-pl-dict'

interface MaterialProps {
  knifeName: KnifeName
  knifePart: KnifePart
  material: PartType
}

type Props = KnivesSet & App

const Calculator: FC<Props> = ({
  pickedKnives,
  configuration,
  language,
}): ReactElement => {
  const { blade, handle, ferrules } = configuration

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getPartPrice = ({
    knifeName,
    knifePart,
    material,
  }: MaterialProps): number => {
    const pickedKnife = sharedKnivesModel.find(
      (knifeModel: KnifeConfig): boolean => knifeModel.name === knifeName
    )
    const pickedPartPricing: Pricing<PartType>[] =
      pickedKnife.pricingTable[knifePart]

    const pickedVariant: Pricing<PartType> = pickedPartPricing.find(
      (pricing): boolean => pricing.type === material
    )

    return parseFloat(pickedVariant.price.toFixed(2))
  }

  const renderMaterial = ({
    knifeName,
    knifePart,
    material,
  }: MaterialProps): ReactElement =>
    material ? (
      <NumberFormat
        fixedDecimalScale
        displayType="text"
        thousandSeparator
        value={getPartPrice({ knifeName, knifePart, material })}
        suffix={` ${translate(CURRENCY, language)}`}
      />
    ) : (
      <> - </>
    )

  // TODO: Rebase to utils
  const calculateTotalPrice = (knifeName: KnifeName): number => {
    const givenKnife = sharedKnivesModel.find(
      (knife): boolean => knife.name === knifeName
    )
    const getUsedMaterials = [
      givenKnife.pricingTable[KnifePart.Blade].find(
        (part): boolean => part.type === blade
      ),
      givenKnife.pricingTable[KnifePart.Handle].find(
        (part): boolean => part.type === handle
      ),
      givenKnife.pricingTable[KnifePart.Ferrules].find(
        (part): boolean => part.type === ferrules
      ),
    ]
    const costOfMaterials = getUsedMaterials.map((material): number =>
      material ? material.price : 0
    )

    return costOfMaterials.reduce((a, b): number => a + b, 0)
  }

  const columns: ColumnProps<CalculatorTableRow>[] = [
    {
      title: translate('Nazwa noża', language),
      colSpan: 1,
      render: (_value, row): ReactElement => (
        <span>
          <span> {row.title[language]} </span>
          <img
            alt="name"
            // @ts-ignore
            style={{ cssFloat: 'right', width: '150px' }}
            src={row.image}
          />
        </span>
      ),
    },
    {
      title: `${translate('Stal', language)} (${blade})`,
      colSpan: 1,
      align: 'right',
      render: (_value, row): ReactElement =>
        renderMaterial({
          knifeName: row.name,
          knifePart: KnifePart.Blade,
          material: blade,
        }),
    },
    {
      title: `${translate('Oprawa', language)} (${handle})`,
      colSpan: 1,
      align: 'right',
      render: (_value, row): ReactElement =>
        renderMaterial({
          knifeName: row.name,
          knifePart: KnifePart.Handle,
          material: handle,
        }),
    },
    {
      title: `${translate('Okucia', language)} (${ferrules})`,
      colSpan: 1,
      align: 'right',
      render: (_value, row): ReactElement =>
        renderMaterial({
          knifeName: row.name,
          knifePart: KnifePart.Ferrules,
          material: ferrules,
        }),
    },
    {
      title: translate('Liczba', language),
      align: 'right',
      render: (): string => '1',
    },
    {
      title: translate('Cena', language),
      align: 'right',
      render: (_value, row): ReactElement => (
        <NumberFormat
          fixedDecimalScale
          displayType="text"
          thousandSeparator
          value={calculateTotalPrice(row.name)}
          suffix={` ${CURRENCY}`}
        />
      ),
    },
  ]

  const rowsData: CalculatorTableRow[] = pickedKnives.map(
    (knifeName): CalculatorTableRow => {
      const knifeData = sharedKnivesModel.find(
        (knifeModel): boolean => knifeModel.name === knifeName
      )
      const { name, image, title } = knifeData
      return { name, image, title }
    }
  )

  const footer = (): ReactElement => {
    const allKnivesPrice = pickedKnives.map((knifeName): number =>
      calculateTotalPrice(knifeName)
    )
    const sum = _.sum(allKnivesPrice).toFixed(2)

    return (
      <NumberFormat
        fixedDecimalScale
        displayType="text"
        thousandSeparator
        value={sum}
        prefix={`${translate('Suma', language)}: `}
        suffix={` ${CURRENCY}`}
      />
    )
  }

  return (
    <Table
      columns={columns}
      dataSource={rowsData}
      locale={{ emptyText: translate('Dodaj nóż do zamówienia', language) }}
      bordered
      pagination={false}
      footer={footer}
      rowKey="name"
    />
  )
}

export default Calculator
