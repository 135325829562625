import {
  BladeComponent,
  Metal,
  SteelSharpening,
  SteelStainless,
} from '~src/types/knife'

export const bladeMaterials: BladeComponent[] = [
  {
    type: Metal.Damasteel,
    name: { PL: 'Stal Damaska', EN: '' },
    description: { PL: 'Damask', EN: '' },
    sharpening: SteelSharpening.easy,
    stainless: SteelStainless.four,
  },
  {
    type: Metal.K110,
    name: { PL: 'Stal K110', EN: 'Steel K110' },
    description: {
      PL: [
        'Austriacka stal narzędziowa ( nie jest to stal nierdzewna ), o bardzo dużej odporności na ścieranie (trudna do ostrzenia), osiąga bardzo wysokie twardości, bardzo dobrze trzyma krawędzie tnące. Jeśli zaakceptuje się patynę ( powłokę z tlenków na powierzchni ostrza ) i trochę bardziej wymagające ostrzenie, to jest to jedna z najlepszych stali na ostrza noży.',
      ],
      EN: [],
    },
    sharpening: SteelSharpening.hard,
    stainless: SteelStainless.two,
  },
  {
    type: Metal.N690,
    name: { PL: 'Stal N690', EN: 'Steel N690' },
    description: {
      PL: [
        'Austriacka narzędziowa stal nierdzewna, ma dobrą odporność na korozję, wysoko się hartuje, jest dość łatwa do ostrzenia i dobrze trzyma krawędzie tnące. Ma dodatki stopowe chromu, kobaltu i wanadu, które są odpowiedzialne za powstawanie twardych węglików. Węgliki poprawiają agresywność cięcia i zwiększają odporność stali na ścieranie. N690 to porządna stal, dająca bardzo dobre właściwości tnące, a jednocześnie łatwa w codziennym użytku.',
      ],
      EN: [],
    },
    sharpening: SteelSharpening.easy,
    stainless: SteelStainless.three,
  },
  {
    type: Metal.M398,
    name: { PL: 'Stal M398', EN: 'Steel M398' },
    description: {
      PL: [
        'M398 z serii MICROCLEAN to szczytowe osiągnięcie austriackich i szwedzkich producentów stali. M398 łączy w sobie bardzo wysoką twardość i odporność na korozję, z jednoczesnym zachowaniem bardzo gęstego ziarna (wielkość cząstek krystalicznych w strukturze stali), odpowiedzialnego za wytrzymałość na złamania i wykruszanie krawędzi. W efekcie daje to stal na ostrza z najwyższej półki. M398 jest tzw. stalą proszkową i powstaje w innym procesie produkcji niż tradycyjne stale narzędziowe, proces ten jest dość skomplikowany, dlatego też stale powstające w technologii proszków są znacznie droższe od innych gatunków.',
      ],
      EN: [],
    },
    sharpening: SteelSharpening.easy,
    stainless: SteelStainless.four,
  },
]
