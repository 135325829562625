import React, { ReactElement } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { Button, Card, Col, Row, Steps, Divider } from 'antd'

import WizardStep from '~components/wizard-steps/types'
import { WizardActionTypes } from '~src/types/actions/wizard-actions'
import { KnivesSet, Wizard, App } from '~src/types/types-store'

import wizardSteps from '~src/models/wizard'
import SEO from '~components/seo'
import Calculator from '~components/widgets/calculator/component'

import AppLayout from '~components/appLayout'
import CreatorStep from '~components/wizard-steps/WizardSteps'
import { AppState } from '~src/store/create-store'
import { toggleWizardStepAC } from '~src/actions/wizard-actions'

import translate from '~src/dicts/en-pl-dict'

const { Step } = Steps

interface LinkDispatchProps {
  toggleStep: typeof toggleWizardStepAC
}

type Props = Wizard & LinkDispatchProps & KnivesSet & App

const KnifeWizard = ({
  currentStepPath,
  toggleStep,
  pickedKnives,
  configuration,
  language,
}: Props): ReactElement<Props> => {
  const getCurrentStep = (): WizardStep =>
    wizardSteps.find((step): boolean => step.stepPath === currentStepPath)

  const currentStep = getCurrentStep()
  const getCurrentStepIndex = (): number => wizardSteps.indexOf(currentStep)

  const isPrevEnable = (): boolean => getCurrentStepIndex() > 0
  const { component, title, stepPath, materialData } = currentStep

  return (
    <AppLayout>
      <SEO title={title[language]} />
      <Card
        style={{ border: 'none' }}
        bodyStyle={{ padding: '0px' }}
        headStyle={{ borderBottom: 'none' }}
        title={
          <div style={{ textAlign: 'center' }}>
            <img
              alt="logo"
              style={{ width: 80 }}
              src="https://klosy.pl/grafika/klosy_logo_grey.jpg"
            />
          </div>
        }
        actions={[
          <Button
            type="link"
            disabled={!isPrevEnable()}
            onClick={(): WizardActionTypes =>
              toggleStep(wizardSteps[getCurrentStepIndex() - 1].stepPath)
            }
          >
            {translate('Wstecz', language)}
          </Button>,
          <Button
            type="link"
            disabled={!pickedKnives.length}
            onClick={(): WizardActionTypes =>
              toggleStep(wizardSteps[getCurrentStepIndex() + 1].stepPath)
            }
          >
            {translate('Następny krok', language)}
          </Button>,
        ]}
      >
        <Steps
          current={getCurrentStepIndex()}
          style={{
            margin: '30px 0',
            paddingBottom: '16px',
            paddingTop: '10px',
          }}
          size="default"
        >
          {wizardSteps.map(
            (item: WizardStep): ReactElement => (
              <Step
                title={item.title[language]}
                description={item.description[language]}
                key={item.stepPath}
              />
            )
          )}
        </Steps>
        <Row style={{ minHeight: '50vh', height: '100%' }}>
          <Col span={24}>
            <CreatorStep
              component={component}
              props={{ stepPath, materialData, header: title[language] }}
            />
          </Col>
        </Row>
        <Row>
          <Divider orientation="left">
            {translate('Podsumowanie', language)}
          </Divider>
          <Col>
            <dl>
              <Calculator
                pickedKnives={pickedKnives}
                configuration={configuration}
                language={language}
              />
            </dl>
          </Col>
        </Row>
      </Card>
    </AppLayout>
  )
}

const mapStateToProps = ({ wizard, knivesSet, app }: AppState): Wizard => ({
  ...wizard,
  ...knivesSet,
  ...app,
})

const mapDispatchToProps = (dispatch: Dispatch): LinkDispatchProps => ({
  toggleStep: bindActionCreators(toggleWizardStepAC, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(KnifeWizard)
