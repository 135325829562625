import WizardStep from '~components/wizard-steps/types'
import { StepPath } from '~src/types/wizard'
import WizardSteps from '~components/wizard-steps/shared-steps'
import { materialTypeValidator, pickedKnivesValidator } from '~src/validators'
import { KnifePart } from '~src/types/knife'
import { bladeMaterials } from '~src/models/materials/_Blade.materials'
import { handleMaterials } from '~src/models/materials/_Handle.materials'
import { ferruleMaterials } from '~src/models/materials/_Ferrules.materials'

const wizardSteps: WizardStep[] = [
  {
    stepPath: StepPath.PickKnives,
    title: { PL: 'Noże', EN: 'Knives' },
    description: {
      PL: 'Wybierz noże które chcesz zamówić',
      EN: 'Select knives you want to order',
    },
    component: WizardSteps.StepPickKnives,
    validator: pickedKnivesValidator,
  },
  {
    stepPath: StepPath.ChooseSteelType,
    title: { PL: 'Stal', EN: 'Steel' },
    description: {
      PL: 'Wybierz stal dla ostrza',
      EN: 'Choose the steel for the blades',
    },
    component: WizardSteps.StepSetMaterial,
    validator: materialTypeValidator,
    materialData: {
      materials: bladeMaterials,
      knifePart: KnifePart.Blade,
    },
  },
  {
    stepPath: StepPath.PickHandleType,
    title: { PL: 'Rękojeść', EN: 'Handle' },
    description: {
      PL: 'Wybierz materiał dla rękojeści',
      EN: 'Chose material for handle',
    },
    component: WizardSteps.StepSetMaterial,
    validator: materialTypeValidator,
    materialData: {
      materials: handleMaterials,
      knifePart: KnifePart.Handle,
    },
  },
  {
    stepPath: StepPath.ChooseFerrules,
    title: { PL: 'Tuleja', EN: 'Ferrules' },
    description: {
      PL: 'Wybierz materiał okucia',
      EN: 'Choose material for ferrule',
    },
    component: WizardSteps.StepSetMaterial,
    validator: materialTypeValidator,
    materialData: {
      materials: ferruleMaterials,
      knifePart: KnifePart.Ferrules,
    },
  },
  {
    stepPath: StepPath.Summary,
    title: { PL: 'Podsumowanie', EN: 'Summary' },
    description: { PL: 'Złóż zamówienie', EN: 'Make an order' },
    component: WizardSteps.StepSummary,
    validator: undefined,
  },
]

export default wizardSteps
