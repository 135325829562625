import { Localization } from '~src/types/knife'

export const CURRENCY = 'zł'
export const COMPANY_EMAIL = 'klosy.info@gmail.com' // Email address where order will send to.
export const WORKSHOP_EMAIL = 'warsztaty@klosy.pl'
export const EMAIL_JS_TOKEN = 'user_uGXusvvTo2umRp2zyeRdV' // TODO: put token into graphcms
export const EMAIL_TEMPLATE_FOR_COMPANY = 'primary'
export const EMAIL_STORE_TEMPLATE = 'knife_doctor'
export const EMAIL_WORKSHOP_TEMPLATE = 'workshop'

export interface DropdownItem {
  id: string
  title: Localization
  price?: Localization
  priceRow?: Localization
}

export const countryShipOptions: DropdownItem[] = [
  {
    id: '1',
    title: {
      EN: 'Poland',
      PL: 'Polska',
    },
  },
  {
    id: '2',
    title: {
      EN: 'EU',
      PL: 'UE',
    },
  },
  {
    id: '3',
    title: {
      EN: 'USA',
      PL: 'USA',
    },
  },
  {
    id: '4',
    title: {
      EN: 'Other',
      PL: 'Inne',
    },
  },
]

export const engravingOptions: DropdownItem[] = [
  {
    id: '1',
    title: {
      EN: 'No',
      PL: 'Nie',
    },
    priceRow: {
      EN: 0,
      PL: 0,
    },
  },
  {
    id: '2',
    title: {
      EN: 'Yes',
      PL: 'Tak',
    },
    price: {
      EN: '+ 15 €',
      PL: '+ 70 zł',
    },
    priceRow: {
      EN: 15,
      PL: 70,
    },
  },
]
