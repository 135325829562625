import { Action } from 'redux'
import { LangCodes } from '~src/types/knife'

export enum SettingsActionName {
  SET_LANGUAGE = '[Settings] SET_LANGUAGE',
}

export interface SetLanguageAction extends Action {
  type: typeof SettingsActionName.SET_LANGUAGE
  lang: LangCodes
}

export type SettingsActionTypes = SetLanguageAction
