import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { Tabs, Divider } from 'antd'

import { AppState } from '~src/store/create-store'
import { setCompletedStepsAC } from '~src/actions/wizard-actions'

import { setMaterialTypeAC } from '~src/actions/knife-actions'
import { materialTypeValidator } from '~src/validators'
import {
  CombinedStepSetMaterialProps,
  StepSetMaterialLinkDispatchProps,
  StepSetMaterialLinkStateProps,
} from '~components/wizard-steps/types'

const { TabPane } = Tabs

const StepSetMaterial = ({
  knivesSet,
  setMaterialType,
  setCompletedSteps,
  stepPath,
  materialData,
  lang,
  header,
}: CombinedStepSetMaterialProps): React.ReactElement => {
  const { knifePart, materials } = materialData

  const handleChange = (key): void => {
    const isCurrentStepValid = materialTypeValidator(key)
    setMaterialType({
      knifePart,
      material: key,
    })

    setCompletedSteps({
      isCurrentStepValid,
      currentStepPath: stepPath,
    })
  }

  return [
    <Divider orientation="left">{header}</Divider>,
    <Tabs
      tabPosition="left"
      type="card"
      activeKey={knivesSet.configuration[knifePart]}
      onChange={(key): void => handleChange(key)}
    >
      {materials.map(
        (material): React.ReactElement => (
          <TabPane tab={material.name[lang]} key={material.type}>
            {material.description[lang]}
          </TabPane>
        )
      )}
    </Tabs>,
  ]
}

const mapStateToProps = ({
  knivesSet,
  app,
}: AppState): StepSetMaterialLinkStateProps => ({
  knivesSet,
  lang: app.language,
})

const mapDispatchToProps = (
  dispatch: Dispatch
): StepSetMaterialLinkDispatchProps => ({
  setMaterialType: bindActionCreators(setMaterialTypeAC, dispatch),
  setCompletedSteps: bindActionCreators(setCompletedStepsAC, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepSetMaterial)
