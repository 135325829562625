import emailjs from 'emailjs-com'
import { StoreOrderData } from '~components/store/types'
import {
  COMPANY_EMAIL,
  EMAIL_JS_TOKEN,
  EMAIL_TEMPLATE_FOR_COMPANY,
  EMAIL_STORE_TEMPLATE,
  EMAIL_WORKSHOP_TEMPLATE,
  WORKSHOP_EMAIL,
} from '~src/constants/constants'

export interface OrderEmailData {
  customerEmail: string
  customerDescription: string
  customerName: string
  customerPhone: string
  knives: string
  countryShip: string
  engraving: string
  wood: string
  steel: string
  priceOrder: string
  presetName?: string
}

export default function sendOrder({
  customerEmail,
  customerDescription,
  customerName,
  customerPhone,
  knives,
  countryShip,
  engraving,
  wood,
  steel,
  priceOrder,
  presetName = '',
}: OrderEmailData): Promise<any> {
  const body = {
    replyTo: COMPANY_EMAIL,
    customerName,
    customerEmail,
    customerDescription,
    customerPhone,
    knives,
    countryShip,
    engraving,
    wood,
    steel,
    priceOrder,
    presetName,
  }

  // TODO: Add also send email to customer.
  return (
    emailjs
      .send('mailjet', EMAIL_TEMPLATE_FOR_COMPANY, body, EMAIL_JS_TOKEN)
      // eslint-disable-next-line no-console
      .then(
        (response): any => response,
        (err): Error => err
      )
  )
}

export const sendStoreOrder = ({
  shipping,
  name,
  email,
  phone,
  street,
  postalCode,
  city,
  comment,
  invoiceRequired,
  company,
  taxNo,
  companyAddress,
  companyPostalCode,
  companyCity,
  price,
  knife,
  itemId,
  storeId,
  parsedKnifeName,
}: StoreOrderData & {
  price: string
  knife: string
  itemId: string
}): Promise<any> => {
  const body = {
    replyTo: COMPANY_EMAIL,
    shipping,
    name,
    email,
    phone,
    street,
    postalCode,
    city,
    comment,
    invoiceRequired,
    company,
    taxNo,
    companyAddress,
    companyPostalCode,
    companyCity,
    price,
    knife,
    itemId,
    storeId,
    parsedKnifeName,
  }

  return (
    emailjs
      .send('mailjet', EMAIL_STORE_TEMPLATE, body, EMAIL_JS_TOKEN)
      // eslint-disable-next-line no-console
      .then(
        (response): any => response,
        (err): Error => err
      )
  )
}

export const sendWorkshopBooking = ({
  name,
  nameMember,
  email,
  phone,
  giftCard,
  bookings,
  comment,
}: Record<string, string>) => {
  const body = {
    replyTo: WORKSHOP_EMAIL,
    name,
    nameMember,
    email,
    phone,
    giftCard,
    bookings,
    comment,
  }

  return (
    emailjs
      .send('mailjet', EMAIL_WORKSHOP_TEMPLATE, body, EMAIL_JS_TOKEN)
      // eslint-disable-next-line no-console
      .then(
        (response): any => response,
        (err): Error => err
      )
  )
}
